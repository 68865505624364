<template>
    <div class="course__module">
        <div class="course__module-header">
            <span class="course__module-title">
                {{ moduleIndex + ' модуль. ' + module.name }}
            </span>
            <module-submenu
                :module="module"
                :on-edit="editModule"
                :on-delete="deleteModule"
                class="module-admin-action"
                v-if="isEditorCourse && accessEdit"
            />
            <span
                v-tooltip="{content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7}"
                v-else-if="isEditorCourse && !accessEdit"
                class="submenu__icon"
            />
        </div>
        <div class="course__module-body">
            <draggable
                class="list-group"
                tag="div"
                v-bind="dragOptions"
                v-model="module.lessons"
                @start="drag = true"
                @end="afterDrug"
                handle=".course__lesson-dragicon"
            >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <course-lesson
                        v-for="(lesson, index) in module.lessons"
                        :lesson-index="index+1"
                        :module-index="moduleIndex"
                        :module-id="module.id"
                        :key="`lesson-${lesson.id}`"
                        :lesson="lesson"
                        :course="course"
                        :isEditorCourse="isEditorCourse"
                        :accessEdit="accessEdit"
                    ></course-lesson>
                </transition-group>
            </draggable>
            <course-test
                v-for="(test, index) in module.tests"
                :key="`test-${test.id}`"
                :module-id="module.id"
                :test="test"
                :course="course"
                :isEditorCourse="isEditorCourse"
                :accessEdit="accessEdit"
            ></course-test>
            <div class="course__module-actions" v-if="isEditorCourse && accessEdit">
                <router-link tag="div" :to="`/courses/${$route.params.course_id}/${module.id}/lesson/add/`"
                             class="course__btn course__btn--outline">+ Добавить урок
                </router-link>
                <router-link v-if="module.tests.length === 0" tag="div" :to="`/courses/${$route.params.course_id}/${module.id}/test/add/`"
                             class="course__btn course__btn--outline">+ Добавить тест
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import session from '@/api/session'
import draggable from 'vuedraggable'
import placeholders from 'assets/js/placeholders'

export default {
    name: 'CourseModule',
    components: {
        CourseLesson : () => import('@/components/course/CourseLesson'),
        CourseTest : () => import('@/components/course/CourseTest'),
        Icon : () => import('@/components/icon/Icon'),
        ModuleSubmenu : () => import('./ModuleSubmenu'),
        draggable
    },
    props: ['module', 'isEditorCourse', 'moduleIndex', 'course', 'afterDeleteModule', 'accessEdit'],
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    data () {
        return {
            showDropdown: false,
            drag: false,
            placeholders
        }
    },
    methods: {
        async editModule (name) {
            const request = await session.put(`/api/v1/course-module/${this.module.id}/`, {
                name: name,
                course: this.course.id
            })
            this.module.name = request.data.name
        },
        async deleteModule(data) {
            this.$swal({
                customClass: {
                    confirmButton: 'btn btn-lg btn-alt-success m-5',
                    cancelButton: 'btn btn-lg btn-alt-danger m-5'
                },
                title: 'Удалить модуль?',
                text: data.name,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Да, удалить!',
                cancelButtonText: 'Отменить',
            }).then(async value => {
                if (value.value) {
                    let swal = this.$swal({
                        title: 'Удаление...',
                        icon: 'info',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                    })
                    try {
                        await session.delete(`/api/v1/course-module/${data.id}/`)
                        swal.close()
                        this.$swal({
                            title: 'Отлично',
                            text: 'Модуль удален',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400,
                        });
                        this.afterDeleteModule(data.id);
                    } catch (err) {
                        console.log(err)
                        swal.close()
                        this.$swal({
                            title: 'Ошибка!',
                            text: 'При обработке запроса произошла ошибка на сервере',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1400,
                        })
                    }
                }
            })
        },
        async afterDrug() {
            this.drag = false;
            this.$emit('on-sort');
        }
    },
}
</script>

<style lang="scss">
@import "#sass/v-style";

.course__module {
    padding: 40px;
    margin-top: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    @include below($mobile) {
        margin-top: 16px;
        padding: 32px 24px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        position: relative;

        @include below($mobile) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &-title {
        display: block;
        width: 100%;
    }

    &-body {
        margin-top: 8px;
    }

    &-actions {
        display: flex;
        margin-top: 12px;

        @include below($mobile) {
            display: none;
        }
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    .v-popover {
        display: none !important;
    }
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
</style>
